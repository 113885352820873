.mca {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content:space-evenly;
    flex-wrap: wrap;
    gap: 5rem;
    height: 80dvh;
    min-width: 0;
    min-height: 0;
    padding: 1.5rem 0.5rem;
}

.user-info-section {
    /* border: 1px solid red; */
    /* padding: 100px; */
    /* height: 100vh; */
  }

  @media (max-width: 40rem) {
    .mca{
        flex-direction: column-reverse;
        height: auto;
        align-items:center;
    }
}