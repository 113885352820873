:root {
    --background-color: rgba(247, 243, 234, 1);
    --title-text-color: rgba(54, 53, 53, 1);
    --title-text-hover: rgba(34, 34, 34, 1);
    --body-text-color: rgba(85, 85, 85, 1);
    --caption-text-color: rgba(119, 119, 119, 1);
    --background-hover-color: rgba(230, 220, 200, 1);
    --border: 1px solid rgba(200, 190, 180, 1);
    --shelf-color: rgba(226, 214, 195, 0.6);
    --nav-arrows: var(--title-text-color);
    --nav-arrows-hover: var(--title-text-hover);

    --alt-background-color: rgba(220, 220, 220, 1);
    --alt-title-text-color: rgba(131, 110, 100, 1);
    --alt-title-text-hover: rgba(84, 73, 63, 1);
    --alt-body-text-color: rgba(89, 80, 76, 1);
    --alt-caption-text-color: rgba(140, 130, 125, 1);
    --alt-background-hover-color: rgba(200, 190, 180, 1);
  }
  