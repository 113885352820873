.book-shelf {
    display: flex;
    flex-direction: column;
    width: calc(100dvw/4);
    gap: 1rem;;
    min-width: 0;
    min-height: 0;
}

.book-shelf h1{
    align-self: center;
    color: var(--title-text-color);
    font-size: 24px;
    font-weight: 400;
    line-height: 34.18px;
    text-align: left;
    min-height: 0;

}

.navigation-arrows {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bi {
    cursor: pointer;
    margin: 0 1rem;
}

.navigation-arrows svg {
    fill: var(--nav-arrows);
    cursor: pointer;
    margin: 0 1rem;
}

/* Optional: Add hover effect to change color or opacity */
.navigation-arrows svg:hover {
    fill: var(--nav-arrows-hover); /* Slightly change color on hover */
}

@media (max-width: 78em) {
    .book-shelf{
        width:max-content;
    }
}
