.book-row {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 1.5rem;
    gap: 1rem;
}

.book-row-base{
    width: 100%;
    height: 21px;
    top: 488px;
    left: 111px;
    gap: 0px;
    border-radius: 7px;
    opacity: 1; 
    background: var(--shelf-color);
    box-shadow: 0px 4px 6px 0px rgba(255, 250, 250, 0.6) inset, 
                0px -4px 7px 0px rgba(0, 0, 0, 0.25) inset,
                -2px 15px 20px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 40em) {
    .book-row{
        justify-content: center;
        gap: 1rem;
    }
}
