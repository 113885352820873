/* BookRating.css */
.book-rating {
  padding: 1rem;
  border-radius: 0.5rem;
  min-width: 5rem;
  margin: 1rem auto; /* Added margin for spacing */
}

.book-rating.expanded {
  /* Styles specific to expanded state if needed */
}

.book-rating.collapsed {
  /* Styles specific to collapsed state if needed */
}

.section-title {
  margin-bottom: 1rem;
}

.rating-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 1rem;
}

.rating-field {
  margin-bottom: 1rem;
}

.rating-field legend {
  margin-bottom: 0.5rem;
}

.done-button {
  display: flex;
  justify-content: flex-end;
  align-self: center;
}

.done-button button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
}

.collapsed-view {
  display: flex;
  align-items: center;
}

.collapsed-view legend {
  margin-right: 0.5rem;
}

.collapsed-view .MuiRating-root {
  margin-right: 0.5rem;
}
