.LandingPage {
    displaY: block;
    /* position: relative; */
    min-height: 100dvh;
    background-image: url("../../public/genres_illust-min.png");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    box-sizing: border-box;

    align-items: center;
    justify-content: center;
}

/* .company-logo {
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.company-logo img {

} */

.login-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100dvh;
    /* padding-top: 4rem; To prevent overlap with the logo */
}

@media (max-width: 40em) {
    .company-logo img {
        /* width: 15rem; */
    }

    .login-page-container {
        padding-top: 3rem;
    }
}