.feature-card {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #dadde9;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.illustration-box {
    height: 180px;
    background-color: #e6dcc8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.illustration {
    width: 80px;
    height: 80px;
    background-size: cover;
    background-position: center;
}

.manage-shelves {
    background-image: url("../../public/cat.webp");
}

.personalized-recommendations {
    background-image: url("../../public/cat.webp");
}

.explore-bestsellers {
    background-image: url("../../public/cat.webp");
}

.rate-review-books {
    background-image: url("../../public/cat.webp");
}

.reading-insights {
    background-image: url("../../public/cat.webp");
}

.share-shelves {
    background-image: url("../../public/cat.webp");
}

.feature-description-box {
    padding: 20px;
}

.feature-title {
    font-size: 18px;
    color: var(--title-text-color);
    margin-bottom: 10px;
    font-weight: bold;
}

.feature-description {
    font-size: 16px;
    color: var(--title-text-color);
}