.carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .carousel-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
  }
  
  .carousel-item {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
    color: rgba(237, 238, 213, 0.9);
  }

  .carousel-button :hover{
    color: rgba(255, 255, 255, 0.99);
  }
  
  .carousel-button:first-of-type {
    left: 10px;
  }
  
  .carousel-button:last-of-type {
    right: 10px;
  }
  