.navbar {
    /* border: 1px solid black; */
    background: var(--background-color);
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 90px;
    box-sizing: border-box; /* Include padding and border in the element's width and height */
    position: fixed; /* Make the navbar fixed to the top */
    top: 0; /* Align it to the top of the viewport */
    z-index: 1000; /* Ensure it is on top of other elements */
}

.company-logo {
    /* border: 1px solid red; */
    /* height: 80%; */
    /* background: var(--background-color); */
    flex: 1;
    display: flex;
    justify-content: flex-start;
    max-width: 200px;
    padding-right: 10px;
    box-sizing: border-box;
    margin-left: 10px;
    opacity: 60%;
    mix-blend-mode: difference;
}

.company-logo a {
    color: inherit; /* Inherits the parent element's text color */
    text-decoration: none; /* Removes the underline */
}

.company-logo img {
    max-width: 100%;
    max-height: 100%;
    display: block;  /* Removes bottom margin/gap */
}

.company-logo a:visited {
    color: inherit; /* Keeps the color the same for visited links */
    text-decoration: none; /* Keeps the underline removed for visited links */
}


.search-bar-nav {
    /* flex: 1; */
    display: flex;
    justify-content: center;
    width: 50%;
}

/*#32281D*/
.right-links {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    max-width: 200px; /* Adjust maximum width as needed */
    padding-left: 10px; /* Add padding to prevent it sticking to the edge */
    gap: 30px;
    padding-right: 30px;
}

.right-links a {
    margin-left: 15px;
    color: var(--title-text-color);
    text-decoration: none;
    font-size: 1.2rem;
}