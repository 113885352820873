.search-bar-container {
    /* border: 1px solid white; */
    position: relative;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-form {
    /* border: 1px solid red; */
    width:1000%;
    position: relative;
}

.search-icon {
    color: var(--title-text-color);
}

.chevron-icon {
    cursor: pointer;
    color: var(--title-text-color);
}

.chevron-icon :hover{
    cursor: pointer;
    color: var(--title-text-hover);
}

.search-options-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70%;
    background-color: var(--background-color);
    border: var(--border);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 80px;
    font-size: 14px;
}

.search-options:last-child {
    border-bottom: none;
}

.search-options:hover {
    background-color: var(--background-hover-color)
}

.search-options.bookInShelf {
    background-color: var(--background-hover-color)
}

.search-options {
    flex: 1;
    border-bottom: var(--border);
    overflow: hidden;
}

.search-form input {
    width: 100%;
    padding-left: 35%;
    font-size: 16px;
    border: 0px solid #ccc;
    outline: none;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    background-color: inherit;
    color: var(--title-text-color);
}
.search-form input::placeholder {
    color: white;
    color: var(--title-text-color);
}
.search-form input:focus::placeholder {
    color: transparent;
}


/****************** DROPDOWN ******************/

.dropdown-container {
    position: relative;
    width: 100%;
}

.dropdown-container input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.dropdown-list {
    position: absolute;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0,0,0,0.15);
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
}

.dropdown-list li {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: var(--border);
    cursor: pointer;
}

.dropdown-list li img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: cover; /* This will ensure images are nicely fitted */
}

.dropdown-list li span {
    flex-grow: 1;
}

.search-results-dropdown {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    background: var(--background-color);
    width: 100%;
    border-radius: 0 0 34.5px 34.5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    top: calc(100% - 1px);
    left: 15%;
    z-index: 1000;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background: var(--background-color);
    color: var(--title-text-color);
}

.dropdown-item:hover {
    background-color: var(--background-hover-color);
}

.dropdown-item-image {
    width: 50px;
    height: 50px;
    top: 134px;
    left: 435px;
    gap: 0px;
    opacity: 0px;

}

.dropdown-item-info {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

.dropdown-item-title {
    font-size: 16px;
    font-weight: bold;
}

.dropdown-item-author {
    font-size: 14px;
    color: var(--caption-text-color)
}

.dropdown-view-more {
    display: flex;
    align-items: center;
    padding: 0px;
    cursor: pointer;
    background: var(--background-color);
    color: var(--title-text-color);
    border-top: var(--border);
    margin: 0;
    height: 15px;
    padding-left: 40%;
    border-radius: 0 0 34.50px 34.5px;
}

.dropdown-view-more:hover {
    background-color: var(--background-color);
}