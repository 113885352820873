body, html, #root {
    background: var(--background-color);
    /* font-family: Malayalam MN; */
    display: flex;
    flex-direction: column;
}

.pages {
    margin: 0;
    flex: 1;
    height: 100vh;
    align-content: center;
    padding-top: 0.5rem;
}

.home {
    display: flex;
    flex-direction: column;
    /* justify-content:center; */
    flex: 1;
    padding-top: 6rem;
}

@media (max-width: 40em) {
    .home{
        padding-top: 1.5rem;
    }
}

@media (min-width: 40em) {
    .pages{
        padding-top: 2rem;
    }
}
